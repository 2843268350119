import type {
  AllPagesQuery,
  CareerQuery,
  CareerQueryVariables,
  Image,
  LocaleString,
  JobOfferSection,
} from '@/.generated/sanityTypes/graphql'
import type {
  AllTrustedAdvisorsQuery,
  TrustedAdvisorQuery,
  TrustedAdvisorQueryVariables,
} from '@/.generated/tbfTypes/graphql'
import type { GetStaticPropsContext, GetStaticPropsResult } from 'next/types'
import React from 'react'
import { useRouter } from 'next/router'

import Layout from '@/components/layout'
import Card from '@/components/card'
import PageSection from '@/components/pageSection'
import { career as careerQuery } from './jobs.sanity.graphql'
import { request } from '@/lib/sanityClient'
import { request as requestTbf } from '@/lib/tbfClient'
import { isLocalString } from '../lib/typeHelper'
import { getAllPages, getPageQueryVariables } from '@/lib/query'
import { trustedAdvisor as trustedAdvisorQuery } from '@/pages/contact-partners/[slug].tbf.graphql'
import { AllTrustedAdvisorsContext } from '@/lib/advisorContext'

const revalidationTime = 60 * 30 // once every 30 minutes

const isJobOfferSection = (section: any): section is JobOfferSection => {
  return (
    section?.__typename === 'JobOfferSection' ||
    section?._type === 'jobOfferSection'
  )
}
export const getUniqueJobOfferContacts = (sections?: Array<any> | null) => {
  const uniqueContacts = new Set<string>()
  sections?.forEach((section) => {
    if (isJobOfferSection(section)) {
      section.jobOffers?.forEach((jobOffer) => {
        const contacts = jobOffer?.contacts?.filter(Boolean) as string[]
        if (contacts?.length) {
          contacts.forEach((contact) => uniqueContacts.add(contact))
        }
      })
    }
  })
  return Array.from(uniqueContacts)
}

type CareerProps = {
  data: CareerQuery
  advisorsData: AllTrustedAdvisorsQuery['trustedAdvisors']
  messages: unknown
  isPreview: boolean
  allPages: AllPagesQuery
  jobOffers: any
}

const Career = ({
  data,
  isPreview,
  allPages,
  advisorsData,
  jobOffers,
}: CareerProps) => {
  const { locale = 'de' } = useRouter()
  const page = data.allPage[0]

  return (
    <Layout
      allPages={allPages}
      isPreview={isPreview}
      title={
        (isLocalString(page?.pageHeader?.sectionTitle) &&
          page?.pageHeader?.sectionTitle[locale as keyof LocaleString]) ||
        undefined
      }
      headerFiles={
        [
          ...(page?.image ? [page?.image] : []),
          ...(page?.additionalImages || []),
        ] as Image[]
      }
    >
      <Card
        headerData={page?.pageHeader}
        contentData={page}
        className="text-md mx-auto mt-4 max-w-3xl text-left md:text-lg"
      />
      <AllTrustedAdvisorsContext.Provider value={advisorsData}>
        <>
          {page?.sections?.map((section: any) => {
            return (
              <PageSection
                key={section.id || section._id}
                data={section}
                rootData={data}
                jobOffers={jobOffers}
              />
            )
          })}
        </>
      </AllTrustedAdvisorsContext.Provider>
    </Layout>
  )
}

export async function getStaticProps(
  context: GetStaticPropsContext,
): Promise<GetStaticPropsResult<CareerProps>> {
  const isPreview = context.preview || false
  const queryVariables = getPageQueryVariables('jobs')

  const pageData = await request<CareerQuery, CareerQueryVariables>(
    careerQuery,
    queryVariables,
    isPreview,
  )

  if (pageData.errors)
    throw new Error(`graphql Error occured: ${pageData.errors[0].message}`)

  const JobOfferContacts = getUniqueJobOfferContacts(
    pageData.data.allPage[0]?.sections,
  )

  const tbfData = await requestTbf<
    TrustedAdvisorQuery,
    TrustedAdvisorQueryVariables
  >(trustedAdvisorQuery, { adIds: JobOfferContacts })

  if (tbfData.errors)
    throw new Error(
      `graphql Error in tbf API occured: ${tbfData.errors[0].message}`,
    )

  if (!tbfData.data?.getTrustedAdvisors?.length)
    throw new Error('No Trusted Advisor Data found')

  const jobOfferResult = await fetch(
    'https://api.softgarden.io/api/rest/v3/frontend/jobslist/108467_extern',
    {
      headers: {
        Authorization: `Basic ${process.env.SOFTGARDEN_CLIENT_ID}`,
      },
      next: {
        revalidate: revalidationTime,
      },
    },
  )
  const jobOffers = await jobOfferResult.json()

  return {
    props: {
      data: pageData.data,
      advisorsData: tbfData.data.getTrustedAdvisors,
      messages: (await import(`@/translation/${context.locale}.json`)).default,
      isPreview,
      allPages: await getAllPages(),
      jobOffers: jobOffers.results,
    },
    revalidate: revalidationTime,
  }
}

export default Career
